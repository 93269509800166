import React, { useState } from "react";
import axiosInstance from "../../axios"; // Adjust the path as necessary

const TestingPage = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const createEmailTemplate = (message) => {
    return `
    <!DOCTYPE html>
<html>
<head>
    <style>
        body { font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; }
        .container { background-color: #ffffff; margin: 20px auto; padding: 20px; max-width: 600px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); }
        .header { background-color: #c1c5c1; color: white; padding: 10px; text-align: center; border-top-left-radius: 8px; border-top-right-radius: 8px; }
        .header img { height: 80px; }
        .content { padding: 20px; text-align: center; }
        .content img { max-width: 100%; height: auto; border-radius: 8px; }
        .footer { background-color: #ddd; padding: 10px; text-align: center; font-size: 0.8em; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; }
        a { color: #4CAF50; text-decoration: none; }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <img src="https://trekngo.com/static/media/logo.26660244c5b9f56ec1e4.png" alt="TrekNgo Logo">
            <h1>TrekNgo Adventures</h1>
        </div>
        <div class="content">
            <img src="https://images.pexels.com/photos/2450296/pexels-photo-2450296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Trekking Adventure">
            <h2>Hello Adventurer,</h2>
            <p>Join us on our next trek to the breathtaking Himalayas! Experience the serene beauty of nature like never before.</p>
            <p>For more details, visit our <a href="https://www.trekngo.com">website</a> or contact us directly.</p>
            <p>Best regards,<br><strong>TrekNgo Team</strong></p>
        </div>
        <div class="footer">
            TrekNgo Adventures | <a href="https://www.trekngo.com">www.trekngo.com</a> | 9654749746
        </div>
    </div>
</body>
</html>

    
    `;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const emailHtml = createEmailTemplate(message);
      await axiosInstance.post("/sendEmail", {
        to: email,
        subject: subject,
        html: emailHtml, // Send HTML content
      });
      alert("Email sent successfully");
    } catch (error) {
      console.error("Error sending email", error);
      alert("Failed to send email");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Recipient's email"
      />
      <input
        type="text"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder="Subject"
      />
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Your message"
      />
      <button type="submit">Send Email</button>
    </form>
  );
};

export default TestingPage;
