import React, { useEffect, useState } from "react";
import "./privacy.css";
import { Helmet } from "react-helmet";
import { db } from "../../firebase";

const Privacy = () => {
  const canonicalUrl = "https://trekngo.com/privacy"; // Replace with your page's canonical URL

  const [canonical_data, setCanonicalData] = useState();

  useEffect(() => {
    const fetchCanonicalDetails = async () => {
      try {
        const docRef = db
          .collection("Trek Meta Details")
          .doc("canonicalDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setCanonicalData(doc.data().canonicalData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };
    fetchCanonicalDetails();
  }, []);

  return (
    <div className="privacy-policy-container">
      {canonical_data && canonical_data["/privacy"] && (
        <Helmet>
          <link rel="canonical" href={canonical_data["/privacy"]} />
        </Helmet>
      )}
      <h1>Privacy Policy</h1>
      <p>
        We value the trust you place in Trekngo.com. That's why we insist upon
        the highest standards for secure transactions and customer information
        privacy. Please read the following statement to learn about our
        information gathering.
      </p>

      <p>
        Note: Our privacy policy is subject to change at any time without
        notice. To make sure you are aware of any changes, please review this
        policy periodically.
      </p>

      <h2 style={{ textAlign: "center" }}>
        Personally Identifiable Information Collection
      </h2>
      <p>
        We collect personally identifiable information (email address, name,
        phone number, etc.) from you when you set up a free account with
        Trekngo.com. We do use your contact information to send you offers based
        on your interests.
      </p>

      <h2 style={{ textAlign: "center" }}>Security Precautions</h2>
      <p>
        Our site has stringent security measures in place to protect against the
        loss, misuse, and alteration of the information under our control.
        Whenever you change or access your account information, we offer the use
        of a secure server. Once your information is in our possession, we
        adhere to strict guidelines, protecting it against unauthorized access.
      </p>

      <h2 style={{ textAlign: "center" }}>Questions?</h2>
      <p>
        Questions regarding this statement should be directed to the following
        address: support@trekngo.com
      </p>
    </div>
  );
};

export default Privacy;
