import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { db } from "../../firebase";

export default function AboutUs() {
  const canonicalUrl = "https://trekngo.com/aboutUs"; // Replace with your page's canonical URL

  const [canonical_data, setCanonicalData] = useState();

  useEffect(() => {
    const fetchCanonicalDetails = async () => {
      try {
        const docRef = db
          .collection("Trek Meta Details")
          .doc("canonicalDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setCanonicalData(doc.data().canonicalData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };
    fetchCanonicalDetails();
  }, []);

  return (
    <div className="privacy-policy-container">
      {canonical_data && canonical_data["/aboutUs"] && (
        <Helmet>
          <link rel="canonical" href={canonical_data["/aboutUs"]} />
        </Helmet>
      )}
      <h1>About us</h1>
      We all have that one thing in our lives that makes us feel alive and keeps
      the fire ignited within us. “A ship is always safe at the shore but it’s
      not built for it”. We don’t believe in being reasonable in her approach
      towards life. For us till the time our dreams do not scare us, we are not
      fueled enough to go for it. Efforts are important but knowing where to put
      the effort makes all the difference. We never expect things to happen for
      us, we are ready to sweat out and achieve it and create our own value.
      With this inspiration, we have set out to pursue our passion for
      travelling and connect with the people of our kind. We believe, we can
      create a community of travelers who are willing to learn and explore life
      in TREKNGO STYLE. TrekNgo was found in 2015, I The founder of the company
      would like to tell you my journey towards starting the business. As a
      strong independent girl, I always loved the idea of packing up my bags and
      letting myself wander the beautiful world we live in. I just realized that
      this was what I loved and ended up starting this service. I believe to
      fulfill the dreams of te wanderers, to makeit easy for that every person
      willing to gotrying adventure and finding himself/herself. With the
      packages designed and also allowing the customized trek we are a company
      showing you paths to building incredible memories on exploring the nature.
      TrekNgo thus provides you with packages that are perfect for adventures
      and trips depending upon the people you plan to go with, family, friends,
      solo and much more options. It also gives you the option to customize your
      trip where in you can select the places only you want to viit and charges
      will be implied accordingly. We at TrekNgo believes that what a person
      expects froma trip is a rejuvenating energy and thus are focused on it
      through our selected destinations. We look forward to plan a trip with you
      and explore the beloved journey of nature and peace together.
    </div>
  );
}
