import React, { useEffect, useState } from "react";
import "./terms.css";
import { Helmet } from "react-helmet";
import { db } from "../../firebase";

const TermsOfUse = () => {
  const canonicalUrl = "https://trekngo.com/t&c"; // Replace with your page's canonical URL

  const [canonical_data, setCanonicalData] = useState();

  useEffect(() => {
    const fetchCanonicalDetails = async () => {
      try {
        const docRef = db
          .collection("Trek Meta Details")
          .doc("canonicalDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setCanonicalData(doc.data().canonicalData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };
    fetchCanonicalDetails();
  }, []);

  return (
    <div className="terms-of-use-container">
      {canonical_data && canonical_data["/t&c"] && (
        <Helmet>
          <link rel="canonical" href={canonical_data["/t&c"]} />
        </Helmet>
      )}

      <h1>Terms of Use</h1>
      <p>
        The website www.trekngo.com is owned by TREKNGO TRAVEL AND TOURISM. All
        bookings here are made with TREKNGO TRAVEL AND TOURISM and the booking
        terms mentioned herein below constitutes the entire agreement between
        the customer and Company. By making a booking with us and/or depositing
        payments with us for tour and travel services you confirm having
        carefully read, understood and accepted the below mentioned ‘Terms of
        Services’.
      </p>

      <h2> Bookings</h2>
      <p>
        Visitors on our website are kindly requested to fill the Enquiry Form
        with all necessary fields mentioned there and submit the same to be sent
        to us. Upon receipt of the booking request or travel enquiry a travel
        expert from our office will get in touch with you with the best
        available information and offers for the desired tour package or travel
        services. In the event the travel requirements are not clear in the
        booking form submitted by you to us, our team may contact you via email
        or phone no. provided by you in the booking form. No booking is
        considered as ‘confirmed’ unless a tour confirmation letter is sent by
        us to the customer after receiving the initial advance payments in our
        account.
      </p>

      <h2> Payments</h2>
      <p>
        <strong>Deposit</strong>
        <br />
        Our tours are subject to availability. To make reservation for a tour
        with us, the customer requires depositing advance payments as may be
        conveyed by our travel consultant during the course of correspondence.
        No booking is treated ‘confirmed’ unless a confirmation letter is
        received by the traveler from our office after depositing the advance
        payments for the trip.
      </p>
      <p>
        <strong>Balance Payments</strong>
        <br />
        It is the sole responsibility of the customer to make the balance
        payments at least 15 days prior to the start of the tour or as may be
        agreed / conveyed by our travel consultant during the course of
        correspondence. In the event of non-receipt of balance payment by the
        cut-off date, the Company will retain the right to treat the tour
        bookings as cancelled and the advance payment may be forfeited.
      </p>
      <p>
        <strong>How to pay for your travel bookings</strong>
        <br />
        To reserve tour package or travel services with us upon demand from our
        office our customers may deposit the payments option mentioned in the
        website www.trekngo.com.The bank account information may be received
        from TrekNgo via email or phone. Our customers may also avail the
        benefits of paying by their credit cards (Visa or MasterCard) using our
        ‘Secure Online Payment Gateway’. For more information on security of
        your private information please read our ‘Privacy Policy’.
      </p>

      <h2> Cancellation and Refund</h2>
      <p>
        All cancellations must be intimated to our head office on phone followed
        by a written confirmation via email. Telephonic cancellation will not be
        accepted as ‘cancelation request’. Cancellations for the
        independent/individual tour services should be made with a notice of not
        less than 7-15 days (According to Situation). The cancellation in case
        of group booking should be made at least 30 days prior to the date of
        commencement of the trip.
      </p>
      <p>
        <strong>Please Note:</strong>
        <br />
        Amount will be refunded through Electronic Transfer or by the card
        (Debit/Credit).
        <br />
        Refund of amount will initiate after deducting the applicable
        cancellation Charges and service charge and both will be settled within
        7 to 21 days of the day of cancellation request approved by TREKNGO
        TRAVEL AND TOURISM.
      </p>
    </div>
  );
};

export default TermsOfUse;
