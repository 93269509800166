import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import "./popularTrekCards.css";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { LocationOn, Star } from "@mui/icons-material";

export default function PopularTrekCards({ data, id='' }) {
  const [{ basket }, dispatch] = useStateValue();
  const [imageUrl, setImageUrl] = useState("");
  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|JPEG)$/.test(url);
  };
  console.log('/0.1/',{data,id});
  console.log('/0.2/', !id ? data?.name.toLowerCase().replace(/[^a-z0-9]+/g, "-"): `${ data?.name.toLowerCase().replace(/[^a-z0-9]+/g, "-")}=${id}`,
);
  
  
  const img =
    "https://images.unsplash.com/photo-1483728642387-6c3bdd6c93e5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=876&q=80";
  return (
    <

    >
      <div className="popularTrekCard">
        <div className="popularTrekCardImage">
          <img
            src={
              isImage(data?.images[0])
                ? data?.images[0]
                : data?.images[0].includes("storage")
                ? data?.images[0]
                : img
            }
            alt=""
            loading="lazy"
          />
          <div className="eachTrekTag">{data?.duration}</div>
        </div>
        <div className="popularTrekCardDetails">
          <div className="popularTrekCardName">
            <Link
            
            to={{
              pathname: !id

                 ? `/treks/${data?.name.toLowerCase().replace(/[^a-z0-9]+/g, "-")}`
                : `/treks/${data?.name.toLowerCase().replace(/[^a-z0-9]+/g, "-")}`,
            }}
            state={{
              trek_redirect_name: !id ? data?.name : `${data?.name}=${id}`,
            }}>
          <span>
            
              {data?.name}
            </span>
            </Link>
            </div>
          <div className="popularTrekCardLocation">
            <LocationOn />
            {data?.route ? data?.route.toLowerCase() : "Location"}
          </div>
          <div
            className="popularTrekRating"
            style={{ display: "flex", alignItems: "center" }}
          >
            {data?.rating ? (
              <>
                {Array.from({ length: parseInt(data?.rating) }).map(() => (
                  <Star style={{ color: "#ff5e00" }} />
                ))}
              </>
            ) : (
              <>
                <Star style={{ color: "#ff5e00" }} />
                <Star style={{ color: "#ff5e00" }} />
                <Star style={{ color: "#ff5e00" }} />
                <Star style={{ color: "#ff5e00" }} />
              </>
            )}
            <span>{data?.rating ? data?.rating : "4.0"}</span>
          </div>
          <div
            className="popularTrekCardButton"
            style={{ display: "flex", flexWrap: "wrap", scale:'0.9' }}
          >
            <div style={{ flex: "1 1 5rem" }} className="popularTrekCardPrice">
              <span style={{ marginRight: ".4rem" }}>₹</span>{" "}
              {data?.discountValue
                ? Math.floor(
                    (data?.price * (100 - parseInt(data?.discountValue))) / 100
                  )
                : Math.floor(data?.price)}
            </div>
            <Link
             
              to={{
                pathname: !id
  
                   ? `/treks/${data?.name.toLowerCase().replace(/[^a-z0-9]+/g, "-")}`
                  : `/treks/${data?.name.toLowerCase().replace(/[^a-z0-9]+/g, "-")}`,
              }}
              state={{
                trek_redirect_name: !id ? data?.name : `${data?.name}=${id}`,
              }}
            >
           
              <button>Book Now</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
