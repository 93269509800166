import React, { useState, useEffect } from "react";

export default function EditMetaDetailsIndividual({ onDataChange }) {
  const [tags, setTags] = useState([
    {
      id: Date.now(),
      tagName: "",
      tagContent: "",
      tagProperty: "",
      tagCanonical: "",
    },
  ]);

  // Notify the parent whenever the tags data changes
  useEffect(() => {
    if (onDataChange) {
      onDataChange(tags);
    }
  }, [tags, onDataChange]);

  const handleChange = (id, field, value) => {
    setTags((prevTags) =>
      prevTags.map((tag) => (tag.id === id ? { ...tag, [field]: value } : tag))
    );
  };

  const handleAdd = () => {
    const newTag = {
      id: Date.now(), // Simple unique id, consider using a better approach in production
      tagName: "",
      tagContent: "",
      tagProperty: "",
      tagCanonical: "",
    };
    setTags((prevTags) => [...prevTags, newTag]);
  };

  const handleRemove = (id) => {
    setTags((prevTags) => prevTags.filter((tag) => tag.id !== id));
  };

  return (
    <div>
      <h2>Edit Meta Details</h2>
      {tags.map((tag) => (
        <div
          key={tag.id}
          style={{
            marginBottom: "10px",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Title</div>
            <input
              type="text"
              value={tag.tagName}
              onChange={(e) => handleChange(tag.id, "tagName", e.target.value)}
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Description</div>
            <input
              type="text"
              value={tag.tagContent}
              onChange={(e) =>
                handleChange(tag.id, "tagContent", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Keywords</div>
            <input
              type="text"
              value={tag.tagProperty}
              onChange={(e) =>
                handleChange(tag.id, "tagProperty", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Canonical Tag</div>
            <input
              type="text"
              value={tag.tagCanonical}
              onChange={(e) =>
                handleChange(tag.id, "tagCanonical", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div>
            <button type="button" onClick={handleAdd}>
              Add
            </button>
            <button
              type="button"
              onClick={() => handleRemove(tag.id)}
              style={{ marginLeft: "5px" }}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
