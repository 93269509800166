import React, { useState, useEffect } from "react";

export default function EditMetaDetailsIndividualEditPage({
  onDataChange,
  metaData,
}) {
  // Function to create an empty tag
  const createEmptyTag = () => ({
    id: Date.now(), // For production, consider a more robust unique ID
    tagName: "",
    tagContent: "",
    tagProperty: "",
    tagCanonical: ""
  });

  // Initialize state: if metaData exists and has items, use it; otherwise, start with one empty tag
  const [tags, setTags] = useState(
    metaData && metaData.length > 0 ? metaData : [createEmptyTag()]
  );

  // Notify the parent whenever the tags data changes
  useEffect(() => {
    if (onDataChange) {
      onDataChange(tags);
    }
  }, [tags, onDataChange]);

  // Update the local tags if the metaData prop changes.
  // If metaData is empty, ensure we always have at least one empty tag.
  useEffect(() => {
    if (metaData && metaData.length > 0) {
      setTags(metaData);
    } else {
      setTags([createEmptyTag()]);
    }
  }, [metaData]);

  const handleChange = (id, field, value) => {
    setTags((prevTags) =>
      prevTags.map((tag) => (tag.id === id ? { ...tag, [field]: value } : tag))
    );
  };

  const handleAdd = () => {
    setTags((prevTags) => [...prevTags, createEmptyTag()]);
  };

  const handleRemove = (id) => {
    setTags((prevTags) => {
      const updatedTags = prevTags.filter((tag) => tag.id !== id);
      // Ensure at least one input remains even after removal
      return updatedTags.length > 0 ? updatedTags : [createEmptyTag()];
    });
  };

  return (
    <div>
      <h2>Edit Meta Details</h2>
      {tags.map((tag) => (
        <div
          key={tag.id}
          style={{
            marginBottom: "10px",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Title</div>
            <input
              type="text"
              value={tag.tagName}
              onChange={(e) => handleChange(tag.id, "tagName", e.target.value)}
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Description</div>
            <input
              type="text"
              value={tag.tagContent}
              onChange={(e) =>
                handleChange(tag.id, "tagContent", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Keywords</div>
            <input
              type="text"
              value={tag.tagProperty}
              onChange={(e) =>
                handleChange(tag.id, "tagProperty", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Canonical Tag</div>
            <input
              type="text"
              value={tag.tagCanonical}
              onChange={(e) =>
                handleChange(tag.id, "tagCanonical", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div>
            <button type="button" onClick={handleAdd}>
              Add
            </button>
            <button
              type="button"
              onClick={() => handleRemove(tag.id)}
              style={{ marginLeft: "5px" }}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
