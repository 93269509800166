import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Allblog from "../AllBlog/Allblog";
import "./blog.css";
import { Helmet } from "react-helmet";
import { db } from "../../firebase";
export default function Blog() {
  const [meta_data, setMetaData] = useState();
  const [canonical_data, setCanonicalData] = useState();
  const img =
    "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_960_720.jpg";
  const img2 =
    "https://cdn.pixabay.com/photo/2016/05/24/16/48/mountains-1412683_960_720.png";

  const canonicalUrl = "https://trekngo.com/blog"; // Replace with your page's canonical URL

  useEffect(() => {
    const fetchMetaDetails = async () => {
      try {
        const docRef = db.collection("Trek Meta Details").doc("metaDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setMetaData(doc.data().metaData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };
    const fetchCanonicalDetails = async () => {
      try {
        const docRef = db
          .collection("Trek Meta Details")
          .doc("canonicalDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setCanonicalData(doc.data().canonicalData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };

    fetchMetaDetails();
    fetchCanonicalDetails();
  }, []);

  return (
    <div className="blog">
      {meta_data && meta_data["Blogs"] && (
        <>
          <Helmet>
            {meta_data["Blogs"].map((tag, index) => {
              const elements = [];
              if (tag.tagName) {
                elements.push(
                  <title key={`title-${index}`}>{tag.tagName}</title>
                );
              }
              if (tag.tagContent) {
                elements.push(
                  <meta
                    key={`desc-${index}`}
                    name="description"
                    content={tag.tagContent}
                  />
                );
              }
              if (tag.tagProperty) {
                elements.push(
                  <meta
                    key={`keywords-${index}`}
                    name="keywords"
                    content={tag.tagProperty}
                  />
                );
              }
              return elements;
            })}
          </Helmet>
        </>
      )}

      {canonical_data && canonical_data["/blog"] && (
        <Helmet>
          <link rel="canonical" href={canonical_data["/blog"]} />
        </Helmet>
      )}
      <Allblog />
    </div>
  );
}
