import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

export default function EditCanonicalTags() {
  // Use the paths (routes) defined in your App component
  const paths = [
    "/",
    "/treks",
    "/treks/:id (indivdual Treks)",
    "/blog",
    "/blog/:id (indivdual Blogs)",
    "/privacy",
    "/aboutUs",
    "/t&c",
    "/peacefulStays/:id (indivdual Stays)",
    "/stays/:id",
    "/perfectEscape/:id (indivdual Escapes)",
    "/blog/addBlog",
  ];

  // Initialize canonicalData state as an object with each path mapping to an empty string.
  const [canonicalData, setCanonicalData] = useState(() => {
    const initialData = {};
    paths.forEach((path) => {
      initialData[path] = ""; // initially empty
    });
    return initialData;
  });

  // Track the currently selected path from the dropdown.
  const [selectedPath, setSelectedPath] = useState(paths[0]);

  // Handler for updating canonical URL for the selected path.
  const handleChange = (value) => {
    setCanonicalData((prevData) => ({
      ...prevData,
      [selectedPath]: value,
    }));
  };

  // Fetch canonical details from Firebase when the component mounts.
  useEffect(() => {
    const fetchCanonicalData = async () => {
      try {
        const docRef = db
          .collection("Trek Meta Details")
          .doc("canonicalDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Ensure the structure in Firebase matches { canonicalData: { ... } }
          setCanonicalData(doc.data().canonicalData);
        } else {
          console.log("No canonical details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching canonical details:", error);
      }
    };

    fetchCanonicalData();
  }, []);

  // Save the updated canonical data to Firebase.
  const handleSave = () => {
    // Create a cleaned version of the canonicalData where the keys
    // have the extra " (indivdual ...)" removed.
    const cleanedCanonicalData = {};
    Object.keys(canonicalData).forEach((path) => {
      // Remove any portion starting from " (" (including the space and parenthesis)
      const cleanPath = path.includes(" (") ? path.split(" (")[0] : path;
      cleanedCanonicalData[cleanPath] = canonicalData[path];
    });

    console.log(
      "Save button clicked! Cleaned canonical data:",
      cleanedCanonicalData
    );

    db.collection("Trek Meta Details")
      .doc("canonicalDetails")
      .set({ canonicalData: cleanedCanonicalData })
      .then(() => {
        alert("Canonical tags saved successfully!");
      })
      .catch((error) => {
        console.error("Error saving canonical details:", error);
      });
  };

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ fontSize: "24px", marginBottom: "10px" }}>
        Edit Canonical Tags
      </div>

      {/* Dropdown to select a path */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="path-select" style={{ marginRight: "10px" }}>
          Select Path:
        </label>
        <select
          id="path-select"
          value={selectedPath}
          onChange={(e) => setSelectedPath(e.target.value)}
        >
          {paths.map((path) => (
            <option key={path} value={path}>
              {path}
            </option>
          ))}
        </select>
      </div>

      {/* Input for entering the canonical URL for the selected path */}
      <div style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <div>
            Canonical URL for <strong>{selectedPath}</strong>:
          </div>
          <input
            type="text"
            value={canonicalData[selectedPath] || ""}
            onChange={(e) => handleChange(e.target.value)}
            style={{ width: "100%", padding: "5px" }}
            placeholder="Enter canonical URL (e.g. https://www.example.com/page)"
          />
        </div>
      </div>

      {/* Display canonical data for all paths */}
      <div style={{ marginTop: "20px" }}>
        <h2>All Canonical Tags</h2>
        {paths.map((path) => (
          <div
            key={path}
            style={{
              marginBottom: "10px",
              padding: "10px",
              border: "1px solid #eee",
            }}
          >
            <strong>{path}:</strong>{" "}
            {canonicalData[path] ? canonicalData[path] : <em>(empty)</em>}
          </div>
        ))}
      </div>

      {/* Save button */}
      <div style={{ marginTop: "20px" }}>
        <button type="button" onClick={handleSave}>
          Save Canonical Tags
        </button>
      </div>
    </div>
  );
}
