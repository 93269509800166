import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import PopularTrekCards from "./PopularTrekCards";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./popularTreks.css";

export default function PopularTreks() {
  const [data, setData] = useState([]);
  const [customTreks, setCustomTreks] = useState([]);
  const [isCustom, setIsCustom] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomLoading, setIsCustomLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const trekSnapshot = await db.collection("All Trek").get();
        const trekData = trekSnapshot.docs.map((doc) => doc.data());
        setData(trekData);
        setIsLoading(false);

        const customSnapshot = await db.collection("CustomPopularTreks").get();
        const customData = customSnapshot.docs.map((doc) => doc.data());
        setIsCustom(customData[0]?.isCustom || false);
        setCustomTreks(customData[0]?.selectedPopularTreks || []);
        setIsCustomLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
        setIsCustomLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(data, ">>>>>>>>>");
  }, [data]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "3px solid #ff5e00",
        margin: "2rem auto",
      }}
      className="loader"
    ></div>
  );

  return (
    <div className="popularTreks">
      <div className="popularTreksHeading">
        <span style={{ color: "black" }}>Most</span> <span>Popular</span>{" "}
        <span style={{ color: "black" }}>Treks</span>
      </div>
      <div className="popularTreksDetails">
        Choose from the best Himalayan treks of all time.
      </div>
      {isLoading || isCustomLoading ? (
        <Loader />
      ) : (
        <div className="popularTrekCards">
          <Splide
            options={{
              perPage: 4,
              rewind: true,
              perMove: 1,
              pagination: false,
              gap: "2rem",
              breakpoints: {
                380: {
                  perPage: 1,
                },
                768: {
                  perPage: 2,
                },
                1300: {
                  perPage: 3,
                  gap: "3rem",
                },
              },
            }}
            aria-label="popular treks"
          >
            {(isCustom ? customTreks : data)?.map((d, index) => (
              <SplideSlide key={index}>
                <PopularTrekCards data={d?.Details} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )}
    </div>
  );
}
