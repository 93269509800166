import { useForm, ValidationError } from "@formspree/react";
import "./custom.css";

import React, { useState } from "react";
import axiosInstance from "../../axios"; // Adjust the import path as necessary

function Custom() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    participantsNumber: "",
    ageGroup: "",
    expeditionPlace: "",
    date: "",
    budget: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const createEmailTemplate = () => {
    return `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
        body { font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; }
        .container { background-color: #ffffff; margin: 20px auto; padding: 20px; max-width: 600px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); }
        .header { background-color: #c1c5c1; color: white; padding: 10px; text-align: center; border-top-left-radius: 8px; border-top-right-radius: 8px; }
        .header img { height: 80px; }
        .content { padding: 20px; text-align: center; }
        .content img { max-width: 100%; height: auto; border-radius: 8px; }
        .footer { background-color: #ddd; padding: 10px; text-align: center; font-size: 0.8em; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; }
        a { color: #4CAF50; text-decoration: none; }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <img src="https://trekngo.com/static/media/logo.26660244c5b9f56ec1e4.png" alt="TrekNgo Logo">
            <h1>TrekNgo Adventures</h1>
          </div>
          <div class="content">
            <img src="https://images.pexels.com/photos/2450296/pexels-photo-2450296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Trekking Adventure">
            <h2>Hello ${formData.name},</h2>
            <p>Thank you for your interest in joining our trek to ${formData.expeditionPlace} on ${formData.date}. We are excited to have you and your group of ${formData.participantsNumber} on this amazing journey.</p>
            <p>Please find the details of your customized trekking plan below:</p>
            <ul>
              <li>Mobile Number: ${formData.mobile}</li>
              <li>Age Group: ${formData.ageGroup}</li>
              <li>Estimated Budget: ${formData.budget}</li>
            </ul>
            <p>For more details, visit our <a href="https://www.trekngo.com">website</a> or contact us directly at 9654749746.</p>
            <p>Best regards,<br><strong>TrekNgo Team</strong></p>
          </div>
          <div class="footer">
            TrekNgo Adventures | <a href="https://www.trekngo.com">www.trekngo.com</a> | 9654749746
          </div>
        </div>
      </body>
      </html>
    `;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const emailHtml = createEmailTemplate();
    try {
      const recipients = `${formData.email}, trekngotravels@gmail.com`;

      await axiosInstance.post("/sendEmail", {
        to: recipients,
        subject: `Your TrekNgo Adventure Plan for ${formData.expeditionPlace}`,
        html: emailHtml,
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form", error);
    }
    setSubmitting(false);
  };

  if (submitted) {
    return (
      <p style={{ textAlign: "center", padding: "1rem 0" }}>
        Thanks for Contacting!
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="custom">
      <h4>Customize your adventure</h4>
      <div>
        <label htmlFor="name">Your Full Name</label>
        <input
          placeholder="John Doe"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <label htmlFor="email">Your Email</label>
        <input
          id="email"
          placeholder="johndoe@example.com"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <label htmlFor="mobile">Your Mobile Number</label>
        <input
          placeholder="+91 (123) 456-7890"
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
        />
        <label htmlFor="participantsNumber">Number of Participants</label>
        <input
          placeholder="5"
          type="text"
          name="participantsNumber"
          value={formData.participantsNumber}
          onChange={handleChange}
        />
        <label htmlFor="ageGroup">Age Group</label>
        <input
          placeholder="25-40 years"
          type="text"
          name="ageGroup"
          value={formData.ageGroup}
          onChange={handleChange}
        />
        <label htmlFor="expeditionPlace">Expedition Name</label>
        <input
          placeholder="Mount Everest Expedition"
          type="text"
          name="expeditionPlace"
          value={formData.expeditionPlace}
          onChange={handleChange}
        />
        <label htmlFor="date">Date of Expedition</label>
        <input
          placeholder="MM/DD/YYYY"
          type="text"
          name="date"
          value={formData.date}
          onChange={handleChange}
        />
        <label htmlFor="budget">Budget</label>
        <input
          placeholder="5000 Rs"
          type="text"
          name="budget"
          value={formData.budget}
          onChange={handleChange}
        />
        <div>
          <button type="submit" disabled={submitting}>
            Confirm
          </button>
        </div>
      </div>
    </form>
  );
}

export default Custom;
