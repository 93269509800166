import React, { useEffect, useState } from "react";
import PeacefulStay from "./Containers/Peaceful Stay/PeacefulStay";
import HeroSection from "./Containers/Hero Section/HeroSection";
import ITE from "./Containers/IncredibleTrekingExperience/ITE";
import PopularTreks from "./Containers/Popular Treks/PopularTreks";
import PopularPackages from "./Containers/PopularPackages/PopularPackages";
import MultidayTour from "./Containers/Multidays Tour/MultidayTour";
import SpiritualTrek from "./Containers/Spiritual Trek/SpiritualTrek";
import Incredible from "./Containers/Incredible/Incredible";
import PerfectEscapeMain from "./Containers/PerfectEscape/PerfectEscapeMain";
import SliderBanner from "./Components/Slider Banner/SilderBanner";
import { db } from "./firebase";
import WhyTrekNgo from "./Containers/WhyTrekNGo/WhyTrekNGo";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const liveBarContainerStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  backgroundColor: "#f1c40f",
  color: "#000",
  fontWeight: "500",
  padding: "5px",
  position: "relative",
  height: "5vh",
  zIndex: -1,
};
function calculateMarqueeSpeed() {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 768) {
    return 20; // slower speed for smaller screens
  } else {
    return 30; // faster speed for larger screens
  }
}

const liveBarContentStyle = {
  display: "inline-block",
  position: "absolute",
  animation: `marquee ${calculateMarqueeSpeed()}s linear infinite`,
};

const LiveBar = () => {
  const [liveBarData, setLiveBarData] = useState("");
  const liveText = db
    .collection("liveBar")
    .doc("liveBar")
    .get()
    .then((snapshot) => setLiveBarData(snapshot.data().liveBar));
  if (!liveBarData.length) return;

  return (
    <div style={liveBarContainerStyle}>
      <style>
        {`
        @keyframes marquee {
          0% {
            transform: translateX(150%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        @media (max-width: 768px) {
          @keyframes marquee {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        }
        `}
      </style>
      <div style={liveBarContentStyle}>{liveBarData}</div>
    </div>
  );
};

export default function MainPage({ data }) {
  const [isWinterAvailable, setIsWinterAvailable] = useState(false);
  const [isSpiritualAvailable, setIsSpiritualAvailable] = useState(false);
  const [meta_data, setMetaData] = useState();

  useEffect(() => {
    db.collection(`Trek Availability`)
      .doc("winterTrek")
      .get()
      .then((snapshot) => {
        setIsWinterAvailable(snapshot.data().availability);
      });
    db.collection(`Trek Availability`)
      .doc("spiritualTrek")
      .get()
      .then((snapshot) => {
        setIsSpiritualAvailable(snapshot.data().availability);
      });
  }, []);

  useEffect(() => {
    const fetchMetaDetails = async () => {
      try {
        const docRef = db.collection("Trek Meta Details").doc("metaDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setMetaData(doc.data().metaData);
          console.log(doc.data().metaData, ">>>>>>>>>>>>>>>>");
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };

    fetchMetaDetails();
  }, []);

  const [canonical_data, setCanonicalData] = useState();

  useEffect(() => {
    const fetchCanonicalDetails = async () => {
      try {
        const docRef = db
          .collection("Trek Meta Details")
          .doc("canonicalDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setCanonicalData(doc.data().canonicalData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };
    fetchCanonicalDetails();
  }, []);

  return (
    <div>
      {meta_data && meta_data["Home Page"] && (
        <>
          <Helmet>
            {meta_data["Home Page"].map((tag, index) => {
              const elements = [];
              if (tag.tagName) {
                elements.push(
                  <title key={`title-${index}`}>{tag.tagName}</title>
                );
              }
              if (tag.tagContent) {
                elements.push(
                  <meta
                    key={`desc-${index}`}
                    name="description"
                    content={tag.tagContent}
                  />
                );
              }
              if (tag.tagProperty) {
                elements.push(
                  <meta
                    key={`keywords-${index}`}
                    name="keywords"
                    content={tag.tagProperty}
                  />
                );
              }
              return elements;
            })}
          </Helmet>
        </>
      )}

      {canonical_data && canonical_data["/"] && (
        <Helmet>
          <link rel="canonical" href={canonical_data["/"]} />
        </Helmet>
      )}

      <HeroSection data={data} />
      <LiveBar />
      <PopularTreks />
      <PerfectEscapeMain />
      {isWinterAvailable === "show" && <PopularPackages />}
      <MultidayTour />
      <SliderBanner />
      {isSpiritualAvailable === "show" && <SpiritualTrek />}
      <PeacefulStay />
      <ITE />
      <WhyTrekNgo />
    </div>
  );
}
