import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

export default function EditMetaDetails() {
  // Define the pages for which you want meta tags
  const pages = [
    "Home Page",
    "Treks Page",
    "Short Trek",
    "Long Trek",
    "Isolated Trek",
    "Camps Page",
    "Home Stays",
    "Blogs",
  ];

  // A helper function to create a new meta tag object with a unique id
  const createMetaTag = () => ({
    id: Date.now() + Math.random(), // simple unique id
    tagName: "",
    tagContent: "",
    tagProperty: "",
  });

  // Initialize metaData state with a default meta tag for each page
  const [metaData, setMetaData] = useState(() => {
    const initialData = {};
    pages.forEach((page) => {
      initialData[page] = [createMetaTag()];
    });
    return initialData;
  });

  // Track which page is currently selected
  const [selectedPage, setSelectedPage] = useState(pages[0]);

  // Add a new meta tag block for the current page
  const handleAdd = () => {
    setMetaData((prevData) => ({
      ...prevData,
      [selectedPage]: [...prevData[selectedPage], createMetaTag()],
    }));
  };

  // Remove a meta tag block from the current page (prevent removal if it's the only tag)
  const handleRemove = (id) => {
    setMetaData((prevData) => {
      const currentTags = prevData[selectedPage];
      if (currentTags.length === 1) return prevData; // optionally, do not remove if only one remains
      return {
        ...prevData,
        [selectedPage]: currentTags.filter((tag) => tag.id !== id),
      };
    });
  };

  // Update a field in a meta tag for the current page
  const handleChange = (id, field, value) => {
    setMetaData((prevData) => {
      const updatedTags = prevData[selectedPage].map((tag) =>
        tag.id === id ? { ...tag, [field]: value } : tag
      );
      return { ...prevData, [selectedPage]: updatedTags };
    });
  };

  // Debug: log the metaData state whenever it changes
  useEffect(() => {
    const fetchMetaDetails = async () => {
      try {
        const docRef = db.collection("Trek Meta Details").doc("metaDetails");
        const doc = await docRef.get();
        if (doc.exists) {
          // Make sure the structure in Firebase matches your expected metaData structure.
          setMetaData(doc.data().metaData);
        } else {
          console.log("No meta details found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching meta details:", error);
      }
    };

    fetchMetaDetails();
  }, []);

  // Test function to be called when save is clicked
  const handleSave = () => {
    console.log("Save button clicked! Meta data:", metaData);
    db.collection(`Trek Meta Details`)
      .doc("metaDetails")
      .set({ metaData })
      .then(() => {
        alert("Meta data has been saved!");
      })
      .catch((error) => {
        alert("Error saving meta data: " + error.message);
      });
  };

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ fontSize: "24px", marginBottom: "10px" }}>
        Edit Meta Details
      </div>

      {/* Dropdown to select a page */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="page-select" style={{ marginRight: "10px" }}>
          Select Page:
        </label>
        <select
          id="page-select"
          value={selectedPage}
          onChange={(e) => setSelectedPage(e.target.value)}
        >
          {pages.map((page) => (
            <option key={page} value={page}>
              {page}
            </option>
          ))}
        </select>
      </div>

      {/* Render the meta tag blocks for the currently selected page */}
      {metaData[selectedPage]?.map((tag) => (
        <div
          key={tag.id}
          style={{
            marginBottom: "10px",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Title</div>
            <input
              type="text"
              value={tag.tagName}
              onChange={(e) => handleChange(tag.id, "tagName", e.target.value)}
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Description</div>
            <input
              type="text"
              value={tag.tagContent}
              onChange={(e) =>
                handleChange(tag.id, "tagContent", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div>Meta Keywords</div>
            <input
              type="text"
              value={tag.tagProperty}
              onChange={(e) =>
                handleChange(tag.id, "tagProperty", e.target.value)
              }
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
          <div>
            <button type="button" onClick={handleAdd}>
              Add
            </button>
            <button
              type="button"
              onClick={() => handleRemove(tag.id)}
              style={{ marginLeft: "5px" }}
            >
              Remove
            </button>
          </div>
        </div>
      ))}

      {/* Render the metaData for all pages in a formatted manner */}
      <div style={{ marginTop: "20px" }}>
        {pages.map((page) => (
          <div key={page} style={{ marginBottom: "20px" }}>
            <h2>{page}</h2>
            {metaData[page].map((tag, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "10px",
                  padding: "10px",
                  border: "1px solid #eee",
                }}
              >
                <div>
                  <strong>Meta Title:</strong>{" "}
                  {tag.tagName ? tag.tagName : <em>(empty)</em>}
                </div>
                <div>
                  <strong>Meta Description:</strong>{" "}
                  {tag.tagContent ? tag.tagContent : <em>(empty)</em>}
                </div>
                <div>
                  <strong>Meta Keywords:</strong>{" "}
                  {tag.tagProperty ? tag.tagProperty : <em>(empty)</em>}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Save button */}
      <div style={{ marginTop: "20px" }}>
        <button type="button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}
